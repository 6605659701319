import cn from 'classnames';
import { useRouter } from 'next/router';
import type { FC, ReactNode } from 'react';
import { memo, useCallback } from 'react';

import { Layout } from '@sravni/react-design-system';

import type { CommonCardProps } from '@src/@types/commonCardProps';
import { usePageContext } from '@src/config/PageContext';
import { BANNER_LIST_POSITION_INDEX, BANNER_TYPE } from '@src/constants/banners';
import { useCardsWithModals } from '@src/containers/list/hooks';
import { getBanners } from '@src/helpers/banners';
import type { IFilter } from '@src/hooks/useFilters';
import { useSelectBanners } from '@src/reducers/banners';
import { useSelectCreditsList } from '@src/reducers/microcredits';
import {
  specOffersLandingPageRouteWebbankirWb1,
  specOffersLandingPageRouteWebbankirWb2,
} from '@src/shared/constants/pageRoutes';
import { is61DaysBestDealsPage, isKalkuljatorPage, isKZContextPage, isZaimyTopPage } from '@src/utils/routing';

import { CardWithDetailsModal } from '../CardWithDetailsModal';

import Card from './components/Card';
import { MessageBlock } from './components/MessageBlock';
import Preloader from './components/Preloader';
import styles from './styles.module.scss';
import { getEmptyProductsTexts, getErrorText } from './utils';

interface IProps {
  className?: string;
  preloadTotal?: number;
  filter: IFilter;
  renderCard?: (props: CommonCardProps) => ReactNode;
}

export const ProductList: FC<IProps> = memo((props: IProps) => {
  const { className, preloadTotal = 16, renderCard = (props) => <Card {...props} /> } = props;
  const { withBanners, listKey } = usePageContext();
  const banners = useSelectBanners();
  const { error, items = [], loading, loadingMore, query } = useSelectCreditsList(listKey);

  const detailModals = useCardsWithModals();

  const { asPath } = useRouter();
  const [pathWithoutQuery] = asPath.split('?');

  // todo(MFO-1413): Необходимо произвести рефакторинг
  const isShowDetailModals =
    !isKalkuljatorPage(pathWithoutQuery) &&
    !is61DaysBestDealsPage(pathWithoutQuery) &&
    !isKZContextPage(pathWithoutQuery) &&
    !isZaimyTopPage(asPath);

  const isOneColumn = [specOffersLandingPageRouteWebbankirWb1, specOffersLandingPageRouteWebbankirWb2].includes(
    pathWithoutQuery,
  );
  const listClassName = cn(styles.list, className, { oneColumn: isOneColumn });

  const renderSlot = useCallback(
    (index, listPosition = BANNER_LIST_POSITION_INDEX) => withBanners && getBanners({ index, banners, listPosition }),
    [withBanners, banners],
  );

  const renderListContent = useCallback(
    () =>
      items.reduce((output, item, index) => {
        const slot = renderSlot(index, BANNER_LIST_POSITION_INDEX);
        if (slot) {
          output.push(
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`${banners[BANNER_TYPE].id}-${index}`}
              className={styles.banner}
            >
              {slot}
            </div>,
          );
        }

        output.push(
          isShowDetailModals ? (
            /**
             * Мы не можем использовать id карточки т.к. они могут повторяться
             * Мы можем использовать индекс т.к. элементы статичны
             */
            <CardWithDetailsModal
              // eslint-disable-next-line react/no-array-index-key
              key={`p-item-${index}`}
              renderCard={renderCard}
              offer={item}
              position={index}
              query={{ amount: query.amount, term: query.term }}
              detailModals={detailModals}
            />
          ) : (
            renderCard({
              /**
               * Мы не можем использовать id карточки т.к. они могут повторяться
               * Мы можем использовать индекс т.к. элементы статичны
               */
              key: `p-item-${index}`,
              offer: item,
              position: index,
              query: { amount: query.amount, term: query.term },
            })
          ),
        );

        return output;
      }, []),
    [items, renderSlot, query, banners, renderCard, detailModals, isShowDetailModals],
  );

  if (error) {
    return <MessageBlock {...getErrorText()} />;
  }

  if (!items.length && !loading) {
    const { message, title } = getEmptyProductsTexts(listKey);

    return (
      <div className={styles.container}>
        <Layout className={styles.layout}>
          <MessageBlock message={message} title={title} />
        </Layout>
      </div>
    );
  }

  if (loading) {
    return (
      <div className={styles.container}>
        <Layout className={styles.layout}>
          <Preloader className={listClassName} itemsCount={preloadTotal} />
        </Layout>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Layout className={styles.layout}>
        <div className={listClassName}>{renderListContent()}</div>

        {loadingMore && <Preloader className={listClassName} itemsCount={preloadTotal} />}
      </Layout>
    </div>
  );
});
